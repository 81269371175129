export const GET_TEMPLATE_FORM_REQUEST =
  'SAPP/FORM_BUILDER_PREVIEW/GET_TEMPLATE_FORM_REQUEST';
export const GET_TEMPLATE_FORM_SUCCESS =
  'SAPP/getTemplateFormReducer/GET_TEMPLATE_FORM_SUCCESS';
export const GET_TEMPLATE_FORM_FAILURE =
  'SAPP/getTemplateFormReducer/GET_TEMPLATE_FORM_FAILURE';

export const POST_PAYMENT_TRANSACTION_REQUEST =
  'SAPP/FORM_BUILDER_PREVIEW/POST_PAYMENT_TRANSACTION_REQUEST';
export const POST_PAYMENT_TRANSACTION_SUCCESS =
  'SAPP/getTemplateFormReducer/POST_PAYMENT_TRANSACTION_SUCCESS';
export const POST_PAYMENT_TRANSACTION_FAILURE =
  'SAPP/getTemplateFormReducer/POST_PAYMENT_TRANSACTION_FAILURE';
