import { call, takeLatest, put, all } from 'redux-saga/effects';
import axios from 'axios';
import config from 'config';

import {
  GET_TEMPLATE_FORM_REQUEST,
  GET_TEMPLATE_FORM_FAILURE,
  GET_TEMPLATE_FORM_SUCCESS,
  POST_PAYMENT_TRANSACTION_REQUEST,
  POST_PAYMENT_TRANSACTION_FAILURE,
  POST_PAYMENT_TRANSACTION_SUCCESS,
} from './constants';

import { SET_OPEN_SNACKBAR } from '../SnackbarProvider/constants';
import { getErrorMessage } from '../../utils/handleError';

const API_TEMPLATE_FORM = '/form-field/template/';
const API_POST_TRANSACTION = '/payment-transaction/pay/';

export function* getTemplateForm({ token, id }) {
  const options = {
    method: 'GET',
    timeout: 12000,
    baseURL: `${config.app.baseUrl}${API_TEMPLATE_FORM}${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const { data } = yield call(axios, options);
    yield put({ type: GET_TEMPLATE_FORM_SUCCESS, data: data.responseObject });
  } catch (error) {
    yield put({ type: GET_TEMPLATE_FORM_FAILURE, error });
    // update error to snackbar messaging
    yield put({
      type: SET_OPEN_SNACKBAR,
      data: { message: getErrorMessage(error) },
    });
  }
}

export function* PostFormFieldResource({ token, data }) {
  // console.log(token, data);
  const options = {
    method: 'POST',
    timeout: 12000,
    baseURL: `${config.app.baseUrl}${API_POST_TRANSACTION}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  };

  try {
    const resp = yield call(axios, options);
    const { data } = resp;
    // console.log(resp);
    // console.log(data);

    if (data.statusCode === '200') {
      const respSuccess = {
        type: 'success',
        message: 'Data has been Saved',
      };
      yield put({
        type: POST_PAYMENT_TRANSACTION_SUCCESS,
        data: data.responseObject,
      });
      yield put({
        type: SET_OPEN_SNACKBAR,
        data: respSuccess,
      });
    } else {
      const respError = {
        type: 'error',
        message: data.message,
      };
      yield put({
        type: POST_PAYMENT_TRANSACTION_SUCCESS,
        data: data.responseObject,
      });
      yield put({
        type: SET_OPEN_SNACKBAR,
        data: respError,
      });
    }
  } catch (error) {
    yield put({ type: POST_PAYMENT_TRANSACTION_FAILURE, error });
    // update error to snackbar messaging
    yield put({
      type: SET_OPEN_SNACKBAR,
      data: { message: getErrorMessage(error) },
    });
  }
}

export function* onGetTemplateForm() {
  yield takeLatest(GET_TEMPLATE_FORM_REQUEST, getTemplateForm);
}

export function* onPostPaymentTransaction() {
  yield takeLatest(POST_PAYMENT_TRANSACTION_REQUEST, PostFormFieldResource);
}

export function* formFieldTemplateWatcher() {
  yield all([call(onGetTemplateForm), call(onPostPaymentTransaction)]);
}
