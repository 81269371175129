import { call, takeLatest, put, all } from 'redux-saga/effects';
import axios from 'axios';
import config from 'config';
import {
  MASTER_TEMPLATE_FAILURE,
  MASTER_TEMPLATE_REQUEST,
  MASTER_TEMPLATE_SUCCESS,
  DELETE_TEMPLATE_SUCCESS,
  DELETE_TEMPLATE_FAILURE,
  DELETE_TEMPLATE_REQUEST,
} from './constants';
import { SET_OPEN_SNACKBAR } from '../SnackbarProvider/constants';
import { getErrorMessage } from '../../utils/handleError';

const API_MASTER_TEMPLATE = '/master-template';

export function* deleteTemplate({ token, id }) {
  const options = {
    method: 'DELETE',
    timeout: 12000,
    baseURL: `${config.app.baseUrl}${API_MASTER_TEMPLATE}/delete/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const { data } = yield call(axios, options);
    yield put({ type: DELETE_TEMPLATE_SUCCESS, data });
  } catch (error) {
    yield put({ type: DELETE_TEMPLATE_FAILURE, error });
    yield put({
      type: SET_OPEN_SNACKBAR,
      data: { message: getErrorMessage(error) },
    });
  }
}

export function* getMasterTemplate({ token }) {
  const options = {
    method: 'GET',
    timeout: 12000,
    baseURL: `${config.app.baseUrl}${API_MASTER_TEMPLATE}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const { data } = yield call(axios, options);
    yield put({ type: MASTER_TEMPLATE_SUCCESS, data });
  } catch (error) {
    yield put({ type: MASTER_TEMPLATE_FAILURE, error });
    // update error to snackbar messaging
    yield put({
      type: SET_OPEN_SNACKBAR,
      data: { message: getErrorMessage(error) },
    });
  }
}

export function* masterTemplateWatcher() {
  yield takeLatest(MASTER_TEMPLATE_REQUEST, getMasterTemplate);
  yield takeLatest(DELETE_TEMPLATE_REQUEST, deleteTemplate);
}
