/* eslint-disable indent */
import produce from 'immer';
import {
  PRICINGS_LIST_FAILURE,
  PRICINGS_LIST_REQUEST,
  PRICINGS_LIST_SUCCESS,
  PRICING_POST_FAILURE,
  PRICING_POST_SUCCESS,
  PRICING_POST_REQUEST,
  PRICING_PUT_FAILURE,
  PRICING_PUT_SUCCESS,
  PRICING_PUT_REQUEST,
  PRICING_LIST_REQUEST,
  PRICING_LIST_FAILURE,
  PRICING_LIST_SUCCESS,
  PRICING_DELETE_REQUEST,
  PRICING_DELETE_FAILURE,
  PRICING_DELETE_SUCCESS,
  TYPE_PRICING_REQUEST,
  TYPE_PRICING_FAILURE,
  TYPE_PRICING_SUCCESS,
} from './constants';

export const initialState = {
  data: null,
  error: '',
  loading: false,
  reset: false,
};

/* eslint-disable default-case, no-param-reassign */
const pricingsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case PRICINGS_LIST_SUCCESS:
        draft.data = action.data;
        draft.loading = false;
        break;
      case PRICINGS_LIST_REQUEST:
        draft.loading = true;
        break;
      case PRICINGS_LIST_FAILURE:
        draft.error = action.error;
        draft.loading = false;
        break;
    }
  });

export const pricingPostReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case PRICING_POST_REQUEST:
        draft.loading = true;
        break;
      case PRICING_POST_SUCCESS:
        draft.loading = false;
        draft.data = action.data;
        draft.reset = true;
        break;
      case PRICING_POST_FAILURE:
        draft.error = action.error;
        draft.loading = false;
        break;
    }
  });

export const pricingPutReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case PRICING_PUT_REQUEST:
        draft.loading = true;
        break;
      case PRICING_PUT_SUCCESS:
        draft.loading = false;
        draft.data = action.data;
        draft.reset = true;
        break;
      case PRICING_PUT_FAILURE:
        draft.error = action.error;
        draft.loading = false;
        break;
    }
  });

export const pricingReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case PRICING_LIST_REQUEST:
        draft.loading = true;
        break;
      case PRICING_LIST_SUCCESS:
        draft.loading = false;
        draft.data = action.data;
        draft.reset = true;
        break;
      case PRICING_LIST_FAILURE:
        draft.error = action.error;
        draft.loading = false;
        break;
    }
  });

export const typePricingReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case TYPE_PRICING_REQUEST:
        draft.loading = true;
        break;
      case TYPE_PRICING_SUCCESS:
        draft.loading = false;
        draft.data = action.data;
        draft.reset = true;
        break;
      case TYPE_PRICING_FAILURE:
        draft.error = action.error;
        draft.loading = false;
        break;
    }
  });

export const pricingDeleteReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case PRICING_DELETE_REQUEST:
        draft.loading = true;
        break;
      case PRICING_DELETE_SUCCESS:
        draft.loading = false;
        draft.data = action.data;
        draft.reset = true;
        break;
      case PRICING_DELETE_FAILURE:
        draft.error = action.error;
        draft.loading = false;
        break;
    }
  });

export default pricingsReducer;
