/* eslint-disable indent */
import produce from 'immer';
import {
  USER_MANAGEMENT_LIST_REQUEST,
  USER_MANAGEMENT_LIST_FAILURE,
  USER_MANAGEMENT_LIST_SUCCESS,
  USER_AUTHORITIES_FAILURE,
  USER_AUTHORITIES_REQUEST,
  USER_AUTHORITIES_SUCCESS,
  POST_USER_FAILURE,
  POST_USER_REQUEST,
  POST_USER_SUCCESS,
  USER_DELETE_REQUEST,
  USER_DELETE_FAILURE,
  USER_DELETE_SUCCESS,
  USER_BY_ID_REQUEST,
  USER_BY_ID_FAILURE,
  USER_BY_ID_SUCCESS,
} from './constants';

export const initialState = {
  data: [],
  paging: {},
  count: '',
  error: '',
  loading: false,
  reset: false,
};

/* eslint-disable default-case, no-param-reassign */
const userListReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case USER_MANAGEMENT_LIST_SUCCESS:
        const pagingPages = state
          ? { ...state.paging, ...action.paging }
          : action.paging;
        draft.data = action.data;
        draft.loading = false;
        draft.count = action.count;
        draft.paging = pagingPages;
        break;
      case USER_MANAGEMENT_LIST_REQUEST:
        draft.loading = true;
        break;
      case USER_MANAGEMENT_LIST_FAILURE:
        draft.error = action.error;
        draft.loading = false;
        break;
    }
  });

/* eslint-disable default-case, no-param-reassign */
export const userAuthoritiesReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case USER_AUTHORITIES_SUCCESS:
        const pagingPages = state
          ? { ...state.paging, ...action.paging }
          : action.paging;
        draft.data = action.data;
        draft.loading = false;
        draft.count = action.count;
        draft.paging = pagingPages;
        break;
      case USER_AUTHORITIES_REQUEST:
        draft.loading = true;
        break;
      case USER_AUTHORITIES_FAILURE:
        draft.error = action.error;
        draft.loading = false;
        break;
    }
  });

/* eslint-disable default-case, no-param-reassign */
export const postUserReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case POST_USER_SUCCESS:
        draft.loading = false;
        draft.data = action.data;
        draft.reset = true;
        break;
      case POST_USER_REQUEST:
        draft.loading = true;
        draft.reset = false;
        break;
      case POST_USER_FAILURE:
        draft.error = action.error;
        draft.loading = false;
        break;
    }
  });

/* eslint-disable default-case, no-param-reassign */
export const userDeleteReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case USER_DELETE_SUCCESS:
        draft.loading = false;
        draft.data = action.data;
        draft.reset = true;
        break;
      case USER_DELETE_REQUEST:
        draft.loading = true;
        draft.reset = false;
        break;
      case USER_DELETE_FAILURE:
        draft.error = action.error;
        draft.loading = false;
        break;
    }
  });

export const userByIdReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case USER_BY_ID_SUCCESS:
        draft.loading = false;
        draft.data = action.data;
        draft.reset = true;
        break;
      case USER_BY_ID_REQUEST:
        draft.loading = true;
        draft.reset = false;
        break;
      case USER_BY_ID_FAILURE:
        draft.error = action.error;
        draft.loading = false;
        break;
    }
  });

export default userListReducer;
