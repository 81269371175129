/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import history from 'utils/history';
import authReducer from 'containers/Authentication/reducer';
import snackbarMessageReducer from 'containers/SnackbarProvider/reducer';
import channelsReducer, {
  channelPostReducer,
  channelReducer,
  channelPutReducer,
  channelDeleteReducer,
} from 'containers/ChannelPage/reducer';
// import productsReducer, {
//   productPostReducer,
//   productReducer,
// } from 'containers/ProductPage(notUse)/reducer';
import pricingsReducer, {
  pricingPostReducer,
  pricingReducer,
  typePricingReducer,
  pricingDeleteReducer,
} from 'containers/PricingPage/reducer';
import productsReducer, {
  productPostReducer,
  productReducer,
} from 'containers/ProductPage/reducer';

import formFieldTypeReducer, {
  postFormFieldResourceReducer,
  getFormFieldByIdReducer,
} from 'containers/FormBuilderPage/reducer';

import getTemplateFormReducer, {
  postPaymentTransactionReducer,
} from 'containers/FormBuilderPreviewPage/reducer';

import masterTemplateReducer from 'containers/ListTemplatePage/reducer';
import userListReducer, {
  userAuthoritiesReducer,
  postUserReducer,
  userDeleteReducer,
} from 'containers/UserManagementPage/reducer';

// import profileUserReducer from "app/views/profile/reducer";
/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    channels: channelsReducer,
    channelPost: channelPostReducer,
    channel: channelReducer,
    channelPut: channelPutReducer,
    products: productsReducer,
    productPost: productPostReducer,
    product: productReducer,
    pricings: pricingsReducer,
    pricingPost: pricingPostReducer,
    pricing: pricingReducer,
    formFieldType: formFieldTypeReducer,
    postFormFieldResource: postFormFieldResourceReducer,
    postPaymentTransaction: postPaymentTransactionReducer,
    getTemplateForm: getTemplateFormReducer,
    masterTemplate: masterTemplateReducer,
    typePricing: typePricingReducer,
    getFormFieldById: getFormFieldByIdReducer,
    channelDelete: channelDeleteReducer,
    pricingDelete: pricingDeleteReducer,
    userManagement: userListReducer,
    userAuthorities: userAuthoritiesReducer,
    postUser: postUserReducer,
    deleteUser: userDeleteReducer,
    // templates: templatesReducer,
    // templatePost: templatePostReducer,
    // template: templateReducer,
    snackbarMessage: snackbarMessageReducer,
    // profile: profileUserReducer,
    ...injectedReducers,
  });

  return rootReducer;
}
