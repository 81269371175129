export const LOGIN = '/login';
export const HOME = '/';
export const CHANNEL = '/channel';
// export const PRODUCT = '/product';
export const PRICING = '/pricing';
export const PRODUCT = '/product';
export const LIST_TEMPLATE = '/list-template';
export const FORM_BUILDER = '/form-builder';
export const CONFIRMATION = '/transaction-confirmation/:id';
export const FORM_BUILDER_PREVIEW = `${FORM_BUILDER}/preview/:id`;
export const FORM_BUILDER_EDIT = `${FORM_BUILDER}/edit/:id`;
export const USER_MANAGEMENT = `/user-management/list`;
export const REPORT = `/report`
