export const USER_MANAGEMENT_LIST_REQUEST =
  'SAPP/USER-MANAGEMENT/USER_MANAGEMENT_LIST_REQUEST';
export const USER_MANAGEMENT_LIST_SUCCESS =
  'SAPP/USER-MANAGEMENT/USER_MANAGEMENT_LIST_SUCCESS';
export const USER_MANAGEMENT_LIST_FAILURE =
  'SAPP/USER-MANAGEMENT/USER_MANAGEMENT_LIST_FAILURE';

export const USER_AUTHORITIES_REQUEST =
  'SAPP/USER-MANAGEMENT/USER_AUTHORITIES_REQUEST';
export const USER_AUTHORITIES_SUCCESS =
  'SAPP/USER-MANAGEMENT/USER_AUTHORITIES_SUCCESS';
export const USER_AUTHORITIES_FAILURE =
  'SAPP/USER-MANAGEMENT/USER_AUTHORITIES_FAILURE';

export const POST_USER_REQUEST = 'SAPP/USER-MANAGEMENT/POST_USER_REQUEST';
export const POST_USER_SUCCESS = 'SAPP/USER-MANAGEMENT/POST_USER_SUCCESS';
export const POST_USER_FAILURE = 'SAPP/USER-MANAGEMENT/POST_USER_FAILURE';

export const USER_DELETE_REQUEST = 'SAPP/USER-MANAGEMENT/USER_DELETE_REQUEST';
export const USER_DELETE_SUCCESS = 'SAPP/USER-MANAGEMENT/USER_DELETE_SUCCESS';
export const USER_DELETE_FAILURE = 'SAPP/USER-MANAGEMENT/USER_DELETE_FAILURE';

export const USER_BY_ID_REQUEST = 'SAPP/USER-MANAGEMENT/USER_BY_ID_REQUEST';
export const USER_BY_ID_SUCCESS = 'SAPP/USER-MANAGEMENT/USER_BY_ID_SUCCESS';
export const USER_BY_ID_FAILURE = 'SAPP/USER-MANAGEMENT/USER_BY_ID_FAILURE';
