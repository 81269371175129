import React from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';

import LoginPage from 'containers/LoginPage/index';
import HomePage from 'containers/HomePage/Loadable';
import ChannelPage from 'containers/ChannelPage/Loadable';
// import ProductPage from 'containers/ProductPage/Loadable';
import PricingPage from 'containers/PricingPage/Loadable';
import ProductPage from 'containers/ProductPage/Loadable';
import FormBuilderPage from 'containers/FormBuilderPage/Loadable';
import FormBuilderPreviewPage from 'containers/FormBuilderPreviewPage/Loadable';
import ListTemplate from 'containers/ListTemplatePage/Loadable';
import UserManagement from 'containers/UserManagementPage/Loadable';
import ConfirmationPage from 'containers/ConfirmationPage/Loadable';
import ReportPage from 'containers/ReportPage/Loadable';
import * as ROUTES from './constants';

import AuthenticateRoute from '../Authentication/AuthenticationRoute';
const index = () => {
  return (
    <BrowserRouter>
      <Switch>
        {/* Unauthenticate route++ */}
        <Route exact path={ROUTES.LOGIN} component={LoginPage} />
        {/* <Route exact path={ROUTES.HOME} component={HomePage} /> */}
        <AuthenticateRoute exact path={ROUTES.HOME} component={HomePage} />
        <AuthenticateRoute
          exact
          path={ROUTES.CHANNEL}
          component={ChannelPage}
        />
        {/* <AuthenticateRoute
          exact
          path={ROUTES.PRODUCT}
          component={ProductPage}
        /> */}
        <AuthenticateRoute
          exact
          path={ROUTES.PRICING}
          component={PricingPage}
        />
        <AuthenticateRoute
          exact
          path={ROUTES.PRODUCT}
          component={ProductPage}
        />
        <AuthenticateRoute
          exact
          path={ROUTES.FORM_BUILDER}
          component={FormBuilderPage}
        />
        <AuthenticateRoute
          exact
          path={ROUTES.FORM_BUILDER_PREVIEW}
          component={FormBuilderPreviewPage}
        />
        <AuthenticateRoute
          exact
          path={ROUTES.FORM_BUILDER_EDIT}
          component={FormBuilderPage}
        />
        <AuthenticateRoute
          exact
          path={ROUTES.LIST_TEMPLATE}
          component={ListTemplate}
        />
        <AuthenticateRoute
          exact
          path={ROUTES.USER_MANAGEMENT}
          component={UserManagement}
        />
        <AuthenticateRoute
          exact
          path={ROUTES.REPORT}
          component={ReportPage}
        />
        <Route path={ROUTES.CONFIRMATION} component={ConfirmationPage} />
      </Switch>
    </BrowserRouter>
  );
};

export default index;
