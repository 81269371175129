export const PRICINGS_LIST_REQUEST = 'SAPP/PRICING/PRICINGS_LIST_REQUEST';
export const PRICINGS_LIST_SUCCESS = 'SAPP/PRICING/PRICINGS_LIST_SUCCESS';
export const PRICINGS_LIST_FAILURE = 'SAPP/PRICING/PRICINGS_LIST_FAILURE';

export const PRICING_LIST_REQUEST = 'SAPP/PRICING/PRICING_LIST_REQUEST';
export const PRICING_LIST_SUCCESS = 'SAPP/PRICING/PRICING_LIST_SUCCESS';
export const PRICING_LIST_FAILURE = 'SAPP/PRICING/PRICING_LIST_FAILURE';

export const PRICING_POST_REQUEST = 'SAPP/PRICING/PRICING_POST_REQUEST';
export const PRICING_POST_SUCCESS = 'SAPP/PRICING/PRICING_POST_SUCCESS';
export const PRICING_POST_FAILURE = 'SAPP/PRICING/PRICING_POST_FAILURE';

export const PRICING_PUT_REQUEST = 'SAPP/PRICING/PRICING_PUT_REQUEST';
export const PRICING_PUT_SUCCESS = 'SAPP/PRICING/PRICING_PUT_SUCCESS';
export const PRICING_PUT_FAILURE = 'SAPP/PRICING/PRICING_PUT_FAILURE';

export const PRICING_DELETE_REQUEST = 'SAPP/PRICING/PRICING_DELETE_REQUEST';
export const PRICING_DELETE_SUCCESS = 'SAPP/PRICING/PRICING_DELETE_SUCCESS';
export const PRICING_DELETE_FAILURE = 'SAPP/PRICING/PRICING_DELETE_FAILURE';

export const TYPE_PRICING_REQUEST = 'SAPP/PRICING/TYPE_PRICING_REQUEST';
export const TYPE_PRICING_SUCCESS = 'SAPP/PRICING/TYPE_PRICING_SUCCESS';
export const TYPE_PRICING_FAILURE = 'SAPP/PRICING/TYPE_PRICING_FAILURE';
