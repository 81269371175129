import produce from 'immer';

import {
  GET_FORM_FIELD_TYPE_REQUEST,
  GET_FORM_FIELD_TYPE_SUCCESS,
  GET_FORM_FIELD_TYPE_FAILURE,
  POST_FORM_FIELD_RESOURCE_FAILURE,
  POST_FORM_FIELD_RESOURCE_REQUEST,
  POST_FORM_FIELD_RESOURCE_SUCCESS,
  GET_FORM_FIELD_BY_ID_FAILURE,
  GET_FORM_FIELD_BY_ID_REQUEST,
  GET_FORM_FIELD_BY_ID_SUCCESS,
} from './constants';

export const initialState = {
  data: null,
  error: '',
  loading: false,
  reset: false,
};

/* eslint-disable default-case, no-param-reassign */
const formFieldTypeReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_FORM_FIELD_TYPE_SUCCESS:
        draft.data = action.data;
        draft.loading = false;
        break;
      case GET_FORM_FIELD_TYPE_REQUEST:
        draft.loading = true;
        break;
      case GET_FORM_FIELD_TYPE_FAILURE:
        draft.error = action.error;
        draft.loading = false;
        break;
    }
  });

/* eslint-disable default-case, no-param-reassign */
export const postFormFieldResourceReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case POST_FORM_FIELD_RESOURCE_SUCCESS:
        draft.data = action.data;
        draft.loading = false;
        draft.reset = false;
        break;
      case POST_FORM_FIELD_RESOURCE_REQUEST:
        draft.loading = true;
        draft.reset = true;
        break;
      case POST_FORM_FIELD_RESOURCE_FAILURE:
        draft.error = action.error;
        draft.loading = false;
        draft.reset = false;
        break;
    }
  });

/* eslint-disable default-case, no-param-reassign */
export const getFormFieldByIdReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_FORM_FIELD_BY_ID_SUCCESS:
        draft.data = action.data;
        draft.loading = false;
        draft.reset = false;
        break;
      case GET_FORM_FIELD_BY_ID_FAILURE:
        draft.loading = true;
        draft.reset = true;
        break;
      case GET_FORM_FIELD_BY_ID_REQUEST:
        draft.error = action.error;
        draft.loading = false;
        draft.reset = false;
        break;
    }
  });
export default formFieldTypeReducer;
