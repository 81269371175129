import produce from 'immer';

import {
  GET_TEMPLATE_FORM_REQUEST,
  GET_TEMPLATE_FORM_FAILURE,
  GET_TEMPLATE_FORM_SUCCESS,
  POST_PAYMENT_TRANSACTION_REQUEST,
  POST_PAYMENT_TRANSACTION_FAILURE,
  POST_PAYMENT_TRANSACTION_SUCCESS,
} from './constants';

export const initialState = {
  data: null,
  error: '',
  loading: false,
  reset: false,
};

/* eslint-disable default-case, no-param-reassign */
const getTemplateFormReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_TEMPLATE_FORM_SUCCESS:
        draft.data = action.data;
        draft.loading = false;
        break;
      case GET_TEMPLATE_FORM_REQUEST:
        draft.loading = true;
        break;
      case GET_TEMPLATE_FORM_FAILURE:
        draft.error = action.error;
        draft.loading = false;
        break;
    }
  });

/* eslint-disable default-case, no-param-reassign */
export const postPaymentTransactionReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case POST_PAYMENT_TRANSACTION_SUCCESS:
        draft.data = action.data;
        draft.loading = false;
        break;
      case POST_PAYMENT_TRANSACTION_REQUEST:
        draft.loading = true;
        break;
      case POST_PAYMENT_TRANSACTION_FAILURE:
        draft.error = action.error;
        draft.loading = false;
        break;
    }
  });

export default getTemplateFormReducer;
