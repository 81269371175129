export const PRODUCTS_LIST_REQUEST = 'SAPP/PRODUCT/PRODUCTS_LIST_REQUEST';
export const PRODUCTS_LIST_SUCCESS = 'SAPP/PRODUCT/PRODUCTS_LIST_SUCCESS';
export const PRODUCTS_LIST_FAILURE = 'SAPP/PRODUCT/PRODUCTS_LIST_FAILURE';

export const PRODUCT_LIST_REQUEST = 'SAPP/PRODUCT/PRODUCT_LIST_REQUEST';
export const PRODUCT_LIST_SUCCESS = 'SAPP/PRODUCT/PRODUCT_LIST_SUCCESS';
export const PRODUCT_LIST_FAILURE = 'SAPP/PRODUCT/PRODUCT_LIST_FAILURE';

export const PRODUCT_POST_REQUEST = 'SAPP/PRODUCT/PRODUCT_POST_REQUEST';
export const PRODUCT_POST_SUCCESS = 'SAPP/PRODUCT/PRODUCT_POST_SUCCESS';
export const PRODUCT_POST_FAILURE = 'SAPP/PRODUCT/PRODUCT_POST_FAILURE';

export const PRODUCT_PUT_REQUEST = 'SAPP/PRODUCT/PRODUCT_PUT_REQUEST';
export const PRODUCT_PUT_SUCCESS = 'SAPP/PRODUCT/PRODUCT_PUT_SUCCESS';
export const PRODUCT_PUT_FAILURE = 'SAPP/PRODUCT/PRODUCT_PUT_FAILURE';
