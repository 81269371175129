import {
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_OUT_REQUEST,
  USER_ACCOUNT_REQUEST,
} from './constants';

/**
 * Changes the input field of the form
 *
 * @param  {object} payload username & password
 *
 */
export function userSignIn(payload) {
  return {
    type: SIGN_IN_REQUEST,
    payload,
  };
}

export function userSignOut() {
  return {
    type: SIGN_OUT_REQUEST,
  };
}

export function userSignInSuccess(data, me) {
  return {
    type: SIGN_IN_SUCCESS,
    data,
    me,
    // device,
  };
}

export function getUserAccount(data) {
  return {
    type: USER_ACCOUNT_REQUEST,
    data,
  };
}
