import { all, call } from 'redux-saga/effects';
import { signInWatchers } from './containers/Authentication/saga';
import { channelsWatcher } from './containers/ChannelPage/saga';
// import { productsWatcher } from './containers/ProductPage(notUse)/saga';
import { pricingsWatcher } from './containers/PricingPage/saga';
import { productsWatcher } from './containers/ProductPage/saga';
import { formFieldWatcher } from './containers/FormBuilderPage/saga';
import { formFieldTemplateWatcher } from './containers/FormBuilderPreviewPage/saga';
import { masterTemplateWatcher } from 'containers/ListTemplatePage/saga';
import { userWatchers } from 'containers/UserManagementPage/saga';

/**
 * Root saga manages watcher lifecycle
 */
export default function* rootSaga() {
  yield all([
    call(signInWatchers),
    call(channelsWatcher),
    // call(productsWatcher),
    call(pricingsWatcher),
    call(productsWatcher),
    call(formFieldWatcher),
    call(formFieldTemplateWatcher),
    call(masterTemplateWatcher),
    call(userWatchers),
  ]);
}
