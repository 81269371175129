import { call, takeLatest, put, all } from 'redux-saga/effects';
import axios from 'axios';
import config from 'config';

import {
  PRODUCTS_LIST_REQUEST,
  PRODUCTS_LIST_FAILURE,
  PRODUCTS_LIST_SUCCESS,
  PRODUCT_POST_REQUEST,
  PRODUCT_POST_FAILURE,
  PRODUCT_POST_SUCCESS,
  PRODUCT_LIST_FAILURE,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_REQUEST,
} from './constants';
import { SET_OPEN_SNACKBAR } from '../SnackbarProvider/constants';
import { getErrorMessage } from '../../utils/handleError';

const API_PRODUCT = '/master-product';

export function* getProducts({ token }) {
  const options = {
    method: 'GET',
    timeout: 12000,
    baseURL: `${config.app.baseUrl}${API_PRODUCT}/all`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const { data } = yield call(axios, options);
    yield put({ type: PRODUCTS_LIST_SUCCESS, data });
  } catch (error) {
    yield put({ type: PRODUCTS_LIST_FAILURE, error });
    // update error to snackbar messaging
    yield put({
      type: SET_OPEN_SNACKBAR,
      data: { message: getErrorMessage(error) },
    });
  }
}

export function* getProduct({ id, token }) {
  // console.log(id);
  const options = {
    method: 'GET',
    timeout: 12000,
    baseURL: `${config.app.baseUrl}${API_PRODUCT}/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const { data } = yield call(axios, options);
    const { responseObject } = data;
    yield put({
      type: PRODUCT_LIST_SUCCESS,
      data: id === '' ? null : responseObject,
    });
  } catch (error) {
    yield put({ type: PRODUCT_LIST_FAILURE, error });
    // update error to snackbar messaging
    yield put({
      type: SET_OPEN_SNACKBAR,
      data: { message: getErrorMessage(error) },
    });
  }
}

export function* postProduct({ token, data, method }) {
  // console.log(token, data, method, 'halo');
  const options = {
    method: method,
    timeout: 12000,
    baseURL: `${config.app.baseUrl}${API_PRODUCT}/${
      method === 'POST' ? 'create' : 'update'
    }`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  };

  try {
    const { data } = yield call(axios, options);
    yield put({ type: PRODUCT_POST_SUCCESS, data });
  } catch (error) {
    yield put({ type: PRODUCT_POST_FAILURE, error });
    // update error to snackbar messaging
    yield put({
      type: SET_OPEN_SNACKBAR,
      data: { message: getErrorMessage(error) },
    });
  }
}

export function* onGetProducts() {
  yield takeLatest(PRODUCTS_LIST_REQUEST, getProducts);
}

export function* onGetProduct() {
  yield takeLatest(PRODUCT_LIST_REQUEST, getProduct);
}

export function* onPostProduct() {
  yield takeLatest(PRODUCT_POST_REQUEST, postProduct);
}

export function* productsWatcher() {
  yield all([call(onGetProducts), call(onPostProduct), call(onGetProduct)]);
}
