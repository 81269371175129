import { call, takeLatest, put, all } from 'redux-saga/effects';
import axios from 'axios';
import config from 'config';
import {
  USER_MANAGEMENT_LIST_REQUEST,
  USER_MANAGEMENT_LIST_FAILURE,
  USER_MANAGEMENT_LIST_SUCCESS,
  USER_AUTHORITIES_FAILURE,
  USER_AUTHORITIES_REQUEST,
  USER_AUTHORITIES_SUCCESS,
  POST_USER_SUCCESS,
  POST_USER_REQUEST,
  POST_USER_FAILURE,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAILURE,
  USER_DELETE_REQUEST,
} from './constants';
import { SET_OPEN_SNACKBAR } from '../SnackbarProvider/constants';
import { getErrorMessage } from '../../utils/handleError';
import { encode } from 'utils/queryString';

const API_USER = '/users';
const API_AUTHORITIES = '/users/authorities';

export function* getUsers({ token, payload }) {
  const page = payload.page;

  const options = {
    method: 'GET',
    timeout: 12000,
    baseURL: `${config.app.baseUrl}${API_USER}?${encode(payload)}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const { data, headers } = yield call(axios, options);
    const count = headers['x-total-count'] || 0;
    // console.log({ [page]: data });
    yield put({
      type: USER_MANAGEMENT_LIST_SUCCESS,
      data,
      paging: { [page]: data },
      count,
    });
  } catch (error) {
    yield put({ type: USER_MANAGEMENT_LIST_FAILURE, error });
    // update error to snackbar messaging
    yield put({
      type: SET_OPEN_SNACKBAR,
      data: { message: getErrorMessage(error) },
    });
  }
}

export function* getAuthorities({ token }) {
  const options = {
    method: 'GET',
    timeout: 12000,
    baseURL: `${config.app.baseUrl}${API_AUTHORITIES}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const { data, headers } = yield call(axios, options);
    const count = headers['x-total-count'] || 0;
    // console.log({ [page]: data });
    yield put({
      type: USER_AUTHORITIES_SUCCESS,
      data,
    });
  } catch (error) {
    yield put({ type: USER_AUTHORITIES_FAILURE, error });
    // update error to snackbar messaging
    yield put({
      type: SET_OPEN_SNACKBAR,
      data: { message: getErrorMessage(error) },
    });
  }
}

export function* postUser({ token, payload }) {
  const options = {
    method: 'POST',
    timeout: 12000,
    baseURL: `${config.app.baseUrl}${API_USER}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: payload,
  };

  try {
    const { data, headers } = yield call(axios, options);
    const count = headers['x-total-count'] || 0;
    // console.log({ [page]: data });
    yield put({
      type: POST_USER_SUCCESS,
      data,
    });

    yield put({
      type: SET_OPEN_SNACKBAR,
      data: { message: 'Your file has been uploaded', type: 'success' },
    });
  } catch (error) {
    yield put({ type: POST_USER_FAILURE, error });
    // update error to snackbar messaging
    yield put({
      type: SET_OPEN_SNACKBAR,
      data: { message: getErrorMessage(error) },
    });
  }
}

export function* deleteUser({ token, id }) {
  const options = {
    method: 'DELETE',
    timeout: 12000,
    baseURL: `${config.app.baseUrl}${API_USER}/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const { data } = yield call(axios, options);
    yield put({ type: USER_DELETE_SUCCESS, data: data.responseObject });
  } catch (error) {
    yield put({ type: USER_DELETE_FAILURE, error });
    yield put({
      type: SET_OPEN_SNACKBAR,
      data: { message: getErrorMessage(error) },
    });
  }
}

export function* userWatchers() {
  yield takeLatest(USER_MANAGEMENT_LIST_REQUEST, getUsers);
  yield takeLatest(USER_AUTHORITIES_REQUEST, getAuthorities);
  yield takeLatest(POST_USER_REQUEST, postUser);
  yield takeLatest(USER_DELETE_REQUEST, deleteUser);
}
