import React from 'react';
import {
  makeStyles,
  Grid,
  TextField,
  Button,
  Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { userSignIn } from 'containers/Authentication/actions';
import usePrevious from 'utils/usePrevious';

import ProgressiveImage from 'react-progressive-image';
// import ProgressiveImage from 'react-progressive-bg-image';
import styled from 'styled-components';
import bgLogin from 'assets/images/background-login.png';
import logo from 'assets/images/logo-with-text.svg';

import { HOME, LOGIN } from 'containers/App/constants';

const useStyles = makeStyles((theme) => ({
  loginWrapper: {
    // background: 'red',
    width: '100%',
    // height: '100vh',
    position: 'relative',
  },
  imageContent: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '100%',
    backgroundPosition: '50%',
    opacity: 0.5,
    // minHeight: 250,
  },
  formWrapper: {
    position: 'absolute',
    top: '40%',
    left: '10%',
  },
  welcome: {
    position: 'absolute',
    top: '10%',
    left: '10%',
  },
  logo: {
    position: 'absolute',
    top: '15%',
    left: '15%',
  },
}));

// assets

const LoginPage = () => {
  const StyledProgressiveImage = styled.img`
    /* height: 600px; */
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100%;
  `;

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { handleSubmit, errors, register } = useForm();
  const { loading, data, isAuth } = useSelector((state) => state.auth);

  // side effect for success login
  const prevData = usePrevious(data);
  React.useEffect(() => {
    const isLoginPage = history.location.pathname === LOGIN;
    if (data && isAuth && isLoginPage) {
      history.push(HOME);
      // checkSubscriptionNotification();
    }
  }, [data, history, loading, prevData]); // eslint-disable-line

  // handle submit login
  const onSubmit = (payload) => {
    // dispatch(userSignIn(payload));
    // console.log(payload);
    // history.push(HOME);
    dispatch(userSignIn(payload));
  };
  return (
    <div classes={classes.loginWrapper}>
      <Grid container classes={{ root: classes.loginWrapper }}>
        <Grid item xs={6}>
          <Typography
            variant="h4"
            component="h2"
            // style={{ fontWeight: 'bold' }}
            className={classes.welcome}
            align="center"
          >
            Welcome To
          </Typography>

          <img src={logo} alt="login-icon" className={classes.logo} />

          <div className={classes.formWrapper}>
            <Typography
              variant="h4"
              component="h1"
              style={{ fontWeight: 'bold' }}
              align="center"
            >
              Login
            </Typography>
            <form
              className={classes.form}
              noValidate
              onSubmit={handleSubmit(onSubmit)}
              autocomplete="off"
            >
              <TextField
                className={classes.inputRoot}
                margin="normal"
                fullWidth
                required
                inputRef={register({
                  required: 'Username is required',
                  minLength: {
                    value: 5,
                    message: 'Length is Not Valid',
                  },
                })}
                id="username"
                label="Username"
                name="username"
                autoFocus
                error={!!errors.username || false}
                helperText={
                  errors.username?.message || errors.username?.type || ''
                }
              />

              <TextField
                className={classes.inputRoot}
                margin="normal"
                required
                fullWidth
                name="password"
                inputRef={register({
                  required: 'Password is required',
                  minLength: {
                    value: 5,
                    message: 'Length is Not Valid',
                  },
                })}
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                error={!!errors.password || false}
                helperText={
                  errors.password?.message || errors.password?.type || ''
                }
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                // disabled={loading}
                className={classes.submit}
              >
                Login
              </Button>
            </form>
          </div>
        </Grid>
        <Grid item xs={6}>
          <ProgressiveImage src={bgLogin} placeholder="tiny-image.jpg">
            {(src, loading) => (
              <StyledProgressiveImage
                style={{ opacity: loading ? 0.5 : 1 }}
                src={src}
                alt="an image"
              />
            )}
          </ProgressiveImage>
        </Grid>
      </Grid>
    </div>
  );
};

export default LoginPage;
