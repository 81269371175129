/* eslint-disable indent */
import produce from 'immer';
import {
  MASTER_TEMPLATE_FAILURE,
  MASTER_TEMPLATE_REQUEST,
  MASTER_TEMPLATE_SUCCESS,
  DELETE_TEMPLATE_REQUEST,
  DELETE_TEMPLATE_FAILURE,
  DELETE_TEMPLATE_SUCCESS,
} from './constants';

export const initialState = {
  data: null,
  error: '',
  loading: false,
  reset: false,
};

/* eslint-disable default-case, no-param-reassign */
const masterTemplateReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case MASTER_TEMPLATE_REQUEST:
        draft.loading = true;
        break;
      case MASTER_TEMPLATE_SUCCESS:
        draft.loading = false;
        draft.data = action.data;
        // draft.reset = true;
        break;
      case DELETE_TEMPLATE_SUCCESS:
        draft.reset = true;
        draft.loading = false;
        break;
      case MASTER_TEMPLATE_FAILURE:
        draft.error = action.error;
        draft.loading = false;
        break;
    }
  });

export default masterTemplateReducer;
