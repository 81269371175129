import { createMuiTheme } from '@material-ui/core/styles';
import { red, blueGrey, amber } from '@material-ui/core/colors';

// import RalewayTTF from 'fonts/Raleway/raleway-regular-wof.woff2';

const raleway = {
  fontFamily: 'Raleway',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Raleway'),
    local('Raleway-Regular'),
    url(https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;700&display=swap) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

// Create a theme instance.
export const themeConfig = {
  typography: {
    fontFamily: 'sans-serif',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [raleway],
      },
    },
    MuiTypography: {
      subtitle1: {
        color: 'gray',
      },
      body1: {
        color: 'gray',
      },
    },
  },
  palette: {
    primary: {
      main: '#1268BB',
      hover: '#0070FF',
    },
    secondary: {
      main: '#fff',
    },
    error: {
      main: red.A400,
    },
    warning: {
      main: '#ffe057',
      dark: amber['700'],
    },
    background: {
      default: '#fff',
    },
    common: {
      blueGrey50: blueGrey['50'],
      blueGrey700: blueGrey['700'],
    },
    blue: {
      main: '#1268BB',
      secondary: '#161e56',
    },
    gray: {
      main: '#ecf1fa',
    },
    black: {
      main: '#343a40',
    },
  },
};

export default createMuiTheme(themeConfig);
