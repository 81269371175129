import { call, takeLatest, put, all } from 'redux-saga/effects';
import axios from 'axios';
import config from 'config';
import { SET_OPEN_SNACKBAR } from '../SnackbarProvider/constants';
import { getErrorMessage } from 'utils/handleError';

import {
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  SIGN_IN_REQUEST,
  TOKEN_KEY,
  USER_KEY,
  SIGN_OUT_REQUEST,
  SIGN_OUT_SUCCESS,
} from './constants';

const API_AUTH = '/authenticate';
const API_USER = '/account';

export function* signInSaga({ payload }) {
  const options = {
    method: 'POST',
    baseURL: config.app.baseUrl + API_AUTH,
    data: payload,
  };

  const optionUserAccount = {
    method: 'GET',
    baseURL: config.app.baseUrl + API_USER,
  };

  try {
    const auth = yield call(axios, options);

    if (auth.status === 200) {
      // console.log(`Bearer ${auth.data.id_token}`);

      localStorage.setItem(TOKEN_KEY, JSON.stringify(auth.data.id_token));
      const userAccount = yield call(axios, {
        method: 'GET',
        baseURL: config.app.baseUrl + API_USER,
        headers: {
          Authorization: `Bearer ${auth.data.id_token}`,
        },
      });
      localStorage.setItem(USER_KEY, JSON.stringify(userAccount.data));
      yield put({
        type: SIGN_IN_SUCCESS,
        data: auth.data,
        me: userAccount.data,
      });
    }

    //   localStorage.setItem(USER_KEY, JSON.stringify(me.data));

    // update reducer
  } catch (error) {
    // console.log(error);
    // update error to snackbar messaging
    yield put({
      type: SET_OPEN_SNACKBAR,
      data: { message: getErrorMessage(error) },
    });
    // update error to module reducer
    yield put({ type: SIGN_IN_FAILURE, error });
  }
}

export function* signOut() {
  yield put({
    type: SIGN_OUT_SUCCESS,
  });
  //   try {
  //     // check if gcm exists

  //     yield put({
  //       type: SIGN_OUT_SUCCESS,
  //     });
  //   } catch (error) {
  //     if (error.response.data.detail === 'Not found.') {
  //       yield put({ type: SIGN_OUT_SUCCESS });
  //     } else {
  //       yield put({
  //         type: SET_OPEN_SNACKBAR,
  //         data: { message: getErrorMessage(error) },
  //       });
  //       // update error to module reducer
  //     }
  //   }
}

export function* onSignIn() {
  yield takeLatest(SIGN_IN_REQUEST, signInSaga);
}

export function* onSignOut() {
  yield takeLatest(SIGN_OUT_REQUEST, signOut);
}

export function* signInWatchers() {
  yield all([call(onSignIn), call(onSignOut)]);
}
