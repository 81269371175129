import { call, takeLatest, put, all } from 'redux-saga/effects';
import axios from 'axios';
import config from 'config';

import {
  PRICINGS_LIST_FAILURE,
  PRICINGS_LIST_REQUEST,
  PRICINGS_LIST_SUCCESS,
  PRICING_POST_FAILURE,
  PRICING_POST_SUCCESS,
  PRICING_POST_REQUEST,
  PRICING_PUT_FAILURE,
  PRICING_PUT_SUCCESS,
  PRICING_PUT_REQUEST,
  PRICING_LIST_REQUEST,
  PRICING_LIST_FAILURE,
  PRICING_LIST_SUCCESS,
  PRICING_DELETE_REQUEST,
  PRICING_DELETE_FAILURE,
  PRICING_DELETE_SUCCESS,
  TYPE_PRICING_FAILURE,
  TYPE_PRICING_SUCCESS,
  TYPE_PRICING_REQUEST,
} from './constants';
import { SET_OPEN_SNACKBAR } from '../SnackbarProvider/constants';
import { getErrorMessage } from '../../utils/handleError';

const API_PRICING = '/master-pricing-rules';
const API_TYPE_PRICING = '/master-type-pricing-rules';

export function* getPricings({ token }) {
  const options = {
    method: 'GET',
    timeout: 12000,
    baseURL: `${config.app.baseUrl}${API_PRICING}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const { data } = yield call(axios, options);
    yield put({ type: PRICINGS_LIST_SUCCESS, data });
  } catch (error) {
    yield put({ type: PRICINGS_LIST_FAILURE, error });
    // update error to snackbar messaging
    yield put({
      type: SET_OPEN_SNACKBAR,
      data: { message: getErrorMessage(error) },
    });
  }
}

export function* getPricing({ id, token }) {
  const options = {
    method: 'GET',
    timeout: 12000,
    baseURL: `${config.app.baseUrl}${API_PRICING}/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const { data } = yield call(axios, options);
    const { responseObject } = data;
    yield put({ type: PRICING_LIST_SUCCESS, data: responseObject });
  } catch (error) {
    yield put({ type: PRICING_LIST_FAILURE, error });
    // update error to snackbar messaging
    yield put({
      type: SET_OPEN_SNACKBAR,
      data: { message: getErrorMessage(error) },
    });
  }
}

export function* postPricing({ token, data, method }) {
  console.log(token, data, method, 'halo');
  const options = {
    method: method,
    timeout: 12000,
    baseURL: `${config.app.baseUrl}${API_PRICING}/${
      method === 'POST' ? 'create' : 'update'
    }`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  };

  try {
    const { data } = yield call(axios, options);
    yield put({ type: PRICING_POST_SUCCESS, data });
  } catch (error) {
    yield put({ type: PRICING_POST_FAILURE, error });
    // update error to snackbar messaging
    yield put({
      type: SET_OPEN_SNACKBAR,
      data: { message: getErrorMessage(error) },
    });
  }
}

export function* getTypePricing({ token }) {
  const options = {
    method: 'GET',
    timeout: 12000,
    baseURL: `${config.app.baseUrl}${API_TYPE_PRICING}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const { data } = yield call(axios, options);
    const { responseObject } = data;
    yield put({ type: TYPE_PRICING_SUCCESS, data: responseObject });
  } catch (error) {
    yield put({ type: TYPE_PRICING_FAILURE, error });
    // update error to snackbar messaging
    yield put({
      type: SET_OPEN_SNACKBAR,
      data: { message: getErrorMessage(error) },
    });
  }
}

export function* deletePricing({ token, id }) {
  const options = {
    method: 'DELETE',
    timeout: 12000,
    baseURL: `${config.app.baseUrl}${API_PRICING}/delete/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const { data } = yield call(axios, options);
    yield put({ type: PRICING_DELETE_SUCCESS, data: data.responseObject });
  } catch (error) {
    yield put({ type: PRICING_DELETE_FAILURE, error });
    yield put({
      type: SET_OPEN_SNACKBAR,
      data: { message: getErrorMessage(error) },
    });
  }
}

export function* onGetTypePricing() {
  yield takeLatest(TYPE_PRICING_REQUEST, getTypePricing);
}

export function* onGetPricings() {
  yield takeLatest(PRICINGS_LIST_REQUEST, getPricings);
}

export function* onGetPricing() {
  yield takeLatest(PRICING_LIST_REQUEST, getPricing);
}

export function* onPostPricing() {
  yield takeLatest(PRICING_POST_REQUEST, postPricing);
}

export function* onDeletePricing() {
  yield takeLatest(PRICING_DELETE_REQUEST, deletePricing);
}

export function* pricingsWatcher() {
  yield all([
    call(onGetPricings),
    call(onPostPricing),
    call(onGetPricing),
    call(onGetTypePricing),
    call(onDeletePricing),
  ]);
}
