/* eslint-disable indent */
import produce from 'immer';
import {
  PRODUCTS_LIST_FAILURE,
  PRODUCTS_LIST_REQUEST,
  PRODUCTS_LIST_SUCCESS,
  PRODUCT_POST_FAILURE,
  PRODUCT_POST_SUCCESS,
  PRODUCT_POST_REQUEST,
  PRODUCT_PUT_FAILURE,
  PRODUCT_PUT_SUCCESS,
  PRODUCT_PUT_REQUEST,
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_FAILURE,
  PRODUCT_LIST_SUCCESS,
} from './constants';

export const initialState = {
  data: null,
  error: '',
  loading: false,
  reset: false,
};

/* eslint-disable default-case, no-param-reassign */
const productsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case PRODUCTS_LIST_SUCCESS:
        draft.data = action.data;
        draft.loading = false;
        break;
      case PRODUCTS_LIST_REQUEST:
        draft.loading = true;
        break;
      case PRODUCTS_LIST_FAILURE:
        draft.error = action.error;
        draft.loading = false;
        break;
    }
  });

export const productPostReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case PRODUCT_POST_REQUEST:
        draft.loading = true;
        draft.reset = false;
        break;
      case PRODUCT_POST_SUCCESS:
        draft.loading = false;
        draft.data = action.data;
        draft.reset = true;
        break;
      case PRODUCT_POST_FAILURE:
        draft.error = action.error;
        draft.loading = false;
        break;
    }
  });

export const productPutReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case PRODUCT_PUT_REQUEST:
        draft.loading = true;
        break;
      case PRODUCT_PUT_SUCCESS:
        draft.loading = false;
        draft.data = action.data;
        draft.reset = true;
        break;
      case PRODUCT_PUT_FAILURE:
        draft.error = action.error;
        draft.loading = false;
        break;
    }
  });

export const productReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case PRODUCT_LIST_REQUEST:
        draft.loading = true;
        break;
      case PRODUCT_LIST_SUCCESS:
        draft.loading = false;
        draft.data = action.data;
        draft.reset = true;
        break;
      case PRODUCT_LIST_FAILURE:
        draft.error = action.error;
        draft.loading = false;
        break;
    }
  });

export default productsReducer;
