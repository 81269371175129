import { call, takeLatest, put, all } from 'redux-saga/effects';
import axios from 'axios';
import config from 'config';

import {
  GET_FORM_FIELD_TYPE_REQUEST,
  GET_FORM_FIELD_TYPE_SUCCESS,
  GET_FORM_FIELD_TYPE_FAILURE,
  POST_FORM_FIELD_RESOURCE_FAILURE,
  POST_FORM_FIELD_RESOURCE_REQUEST,
  POST_FORM_FIELD_RESOURCE_SUCCESS,
  GET_FORM_FIELD_BY_ID_REQUEST,
  GET_FORM_FIELD_BY_ID_FAILURE,
  GET_FORM_FIELD_BY_ID_SUCCESS,
} from './constants';

import { SET_OPEN_SNACKBAR } from '../SnackbarProvider/constants';
import { getErrorMessage } from '../../utils/handleError';

const API_FORM_FIELD_TYPE = '/form-field/type/all';
const API_FORM_FIELD_RESOURCE = '/form-field/';

export function* getFormFieldType({ token }) {
  const options = {
    method: 'GET',
    timeout: 12000,
    baseURL: `${config.app.baseUrl}${API_FORM_FIELD_TYPE}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const { data } = yield call(axios, options);
    yield put({ type: GET_FORM_FIELD_TYPE_SUCCESS, data: data.responseObject });
  } catch (error) {
    yield put({ type: GET_FORM_FIELD_TYPE_FAILURE, error });
    // update error to snackbar messaging
    yield put({
      type: SET_OPEN_SNACKBAR,
      data: { message: getErrorMessage(error) },
    });
  }
}

export function* getFormFieldById({ token, param }) {
  // console.log(token, param);
  const options = {
    method: 'GET',
    timeout: 12000,
    baseURL: `${config.app.baseUrl}${API_FORM_FIELD_RESOURCE}template/${param}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const resp = yield call(axios, options);
  const { data } = resp;
  yield put({
    type: GET_FORM_FIELD_BY_ID_SUCCESS,
    data: data.responseObject,
  });
  // console.log(data);
}

export function* PostFormFieldResource({ token, data, method }) {
  // console.log(token, data, method);
  const options = {
    method: method,
    timeout: 12000,
    baseURL: `${config.app.baseUrl}${API_FORM_FIELD_RESOURCE}${
      method === 'POST' ? 'create' : 'update'
    }`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  };

  try {
    const resp = yield call(axios, options);
    const { data } = resp;
    // console.log(resp);
    // console.log(data);

    if (data.statusCode === '200') {
      const respSuccess = {
        type: 'success',
        message: 'Data has been Saved',
      };
      yield put({
        type: POST_FORM_FIELD_RESOURCE_SUCCESS,
        data: data,
      });
      yield put({
        type: SET_OPEN_SNACKBAR,
        data: respSuccess,
      });
    } else {
      const respError = {
        type: 'error',
        message: data.message,
      };
      yield put({
        type: POST_FORM_FIELD_RESOURCE_SUCCESS,
        data: data.responseObject,
      });
      yield put({
        type: SET_OPEN_SNACKBAR,
        data: respError,
      });
    }
  } catch (error) {
    yield put({ type: POST_FORM_FIELD_RESOURCE_FAILURE, error });
    // update error to snackbar messaging
    yield put({
      type: SET_OPEN_SNACKBAR,
      data: { message: getErrorMessage(error) },
    });
  }
}

export function* onGetFormFieldType() {
  yield takeLatest(GET_FORM_FIELD_TYPE_REQUEST, getFormFieldType);
}

export function* onPostFormFieldResource() {
  yield takeLatest(POST_FORM_FIELD_RESOURCE_REQUEST, PostFormFieldResource);
}

export function* onGetFormFieldById() {
  yield takeLatest(GET_FORM_FIELD_BY_ID_REQUEST, getFormFieldById);
}

export function* formFieldWatcher() {
  yield all([
    call(onGetFormFieldType),
    call(onPostFormFieldResource),
    call(onGetFormFieldById),
  ]);
}
