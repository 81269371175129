/*
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * */

/*
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const SIGN_OUT = 'SAPP/Authentication/SIGN_OUT';
export const SIGN_IN_REQUEST = 'SAPP/Authentication/SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS = 'SAPP/Authentication/SIGN_IN_SUCCESS';
export const SIGN_IN_FAILURE = 'SAPP/Authentication/SIGN_IN_FAILURE';

export const SIGN_OUT_REQUEST = 'SAPP/Authentication/SIGN_OUT_REQUEST';
export const SIGN_OUT_SUCCESS = 'SAPP/Authentication/SIGN_OUT_SUCCESS';
export const SIGN_OUT_FAILURE = 'SAPP/Authentication/SIGN_OUT_FAILURE';

export const USER_ACCOUNT_REQUEST = 'SAPP/Authentication/USER_ACCOUNT_REQUEST';
export const USER_ACCOUNT_SUCCESS = 'SAPP/Authentication/USER_ACCOUNT_SUCCESS';
export const USER_ACCOUNT_FAILURE = 'SAPP/Authentication/USER_ACCOUNT_FAILURE';

export const COMPANY_NAME = 'Netpolitan';
export const TOKEN_KEY = '_token';
export const USER_KEY = '_user';
export const DEVICE_KEY = '_device';
