export const CHANNELS_LIST_REQUEST = 'SAPP/channel/CHANNELS_LIST_REQUEST';
export const CHANNELS_LIST_SUCCESS = 'SAPP/channel/CHANNELS_LIST_SUCCESS';
export const CHANNELS_LIST_FAILURE = 'SAPP/channel/CHANNELS_LIST_FAILURE';

export const CHANNEL_LIST_REQUEST = 'SAPP/channel/CHANNEL_LIST_REQUEST';
export const CHANNEL_LIST_SUCCESS = 'SAPP/channel/CHANNEL_LIST_SUCCESS';
export const CHANNEL_LIST_FAILURE = 'SAPP/channel/CHANNEL_LIST_FAILURE';

export const CHANNEL_POST_REQUEST = 'SAPP/channel/CHANNEL_POST_REQUEST';
export const CHANNEL_POST_SUCCESS = 'SAPP/channel/CHANNEL_POST_SUCCESS';
export const CHANNEL_POST_FAILURE = 'SAPP/channel/CHANNEL_POST_FAILURE';

export const CHANNEL_PUT_REQUEST = 'SAPP/channel/CHANNEL_PUT_REQUEST';
export const CHANNEL_PUT_SUCCESS = 'SAPP/channel/CHANNEL_PUT_SUCCESS';
export const CHANNEL_PUT_FAILURE = 'SAPP/channel/CHANNEL_PUT_FAILURE';

export const CHANNEL_DELETE_REQUEST = 'SAPP/channel/CHANNEL_DELETE_REQUEST';
export const CHANNEL_DELETE_SUCCESS = 'SAPP/channel/CHANNEL_DELETE_SUCCESS';
export const CHANNEL_DELETE_FAILURE = 'SAPP/channel/CHANNEL_DELETE_FAILURE';
