import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { TOKEN_KEY, USER_KEY } from './constants';
import { userSignInSuccess } from './actions';
import { LOGIN } from '../App/constants';

const AuthenticationRoute = ({ component: Component, ...rest }) => {
  const _token = localStorage.getItem(TOKEN_KEY);
  const _user = localStorage.getItem(USER_KEY);
  // const _device = localStorage.getItem(DEVICE_KEY);
  // console.log(_user, _token);

  const dispatch = useDispatch();

  const { isAuth } = useSelector((state) => state.auth);

  // update/persist auth state
  React.useEffect(() => {
    // console.log(isAuth);
    if (!isAuth && _token && _user) {
      dispatch(userSignInSuccess(JSON.parse(_token), JSON.parse(_user)));
    }
  });
  return (
    <Route
      {...rest}
      render={(dataProps) => {
        if (!_token) {
          return <Redirect to={LOGIN} />;
        }
        return <Component {...dataProps} />;
      }}
    />
  );
};

export default AuthenticationRoute;
