const dev = process.env.NODE_ENV === 'development';

// const apiUrl = 'https://opa-ga.atibusinessgroup.com:8443/api';
const apiUrl = 'https://sapp.garuda-indonesia.com/api';

const defaultConfig = {
  app: {
    baseUrl: dev ? 'https://opa-ga.atibusinessgroup.com:8443/api' : apiUrl,
  },
};

export default defaultConfig;
