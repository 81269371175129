import { call, takeLatest, put, all } from 'redux-saga/effects';
import axios from 'axios';
import config from 'config';

import {
  CHANNELS_LIST_REQUEST,
  CHANNELS_LIST_FAILURE,
  CHANNELS_LIST_SUCCESS,
  CHANNEL_POST_REQUEST,
  CHANNEL_POST_FAILURE,
  CHANNEL_POST_SUCCESS,
  CHANNEL_LIST_FAILURE,
  CHANNEL_LIST_SUCCESS,
  CHANNEL_LIST_REQUEST,
  CHANNEL_DELETE_FAILURE,
  CHANNEL_DELETE_SUCCESS,
  CHANNEL_DELETE_REQUEST,
} from './constants';
import { SET_OPEN_SNACKBAR } from '../SnackbarProvider/constants';
import { getErrorMessage } from '../../utils/handleError';

const API_CHANNEL = '/master-channel';

export function* getChannels({ token }) {
  const options = {
    method: 'GET',
    timeout: 12000,
    baseURL: `${config.app.baseUrl}${API_CHANNEL}/all`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const { data } = yield call(axios, options);
    yield put({ type: CHANNELS_LIST_SUCCESS, data });
  } catch (error) {
    yield put({ type: CHANNELS_LIST_FAILURE, error });
    // update error to snackbar messaging
    yield put({
      type: SET_OPEN_SNACKBAR,
      data: { message: getErrorMessage(error) },
    });
  }
}

export function* getChannel({ id, token }) {
  const options = {
    method: 'GET',
    timeout: 12000,
    baseURL: `${config.app.baseUrl}${API_CHANNEL}/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const { data } = yield call(axios, options);
    const { responseObject } = data;
    yield put({ type: CHANNEL_LIST_SUCCESS, data: responseObject });
  } catch (error) {
    yield put({ type: CHANNEL_LIST_FAILURE, error });
    // update error to snackbar messaging
    yield put({
      type: SET_OPEN_SNACKBAR,
      data: { message: getErrorMessage(error) },
    });
  }
}

export function* postChannel({ token, data, method }) {
  // console.log(token, data, method, 'halo');
  const options = {
    method: method,
    timeout: 12000,
    baseURL: `${config.app.baseUrl}${API_CHANNEL}/${
      method === 'POST' ? 'create' : 'update'
    }`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  };

  try {
    const { data } = yield call(axios, options);
    yield put({ type: CHANNEL_POST_SUCCESS, data });
  } catch (error) {
    yield put({ type: CHANNEL_POST_FAILURE, error });
    // update error to snackbar messaging
    yield put({
      type: SET_OPEN_SNACKBAR,
      data: { message: getErrorMessage(error) },
    });
  }
}

export function* deleteChannel({ token, id }) {
  const options = {
    method: 'DELETE',
    timeout: 12000,
    baseURL: `${config.app.baseUrl}${API_CHANNEL}/delete/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const { data } = yield call(axios, options);
    yield put({ type: CHANNEL_DELETE_SUCCESS, data: data.responseObject });
  } catch (error) {
    yield put({ type: CHANNEL_DELETE_FAILURE, error });
    yield put({
      type: SET_OPEN_SNACKBAR,
      data: { message: getErrorMessage(error) },
    });
  }
}

export function* onGetChannels() {
  yield takeLatest(CHANNELS_LIST_REQUEST, getChannels);
}

export function* onGetChannel() {
  yield takeLatest(CHANNEL_LIST_REQUEST, getChannel);
}

export function* onPostChannel() {
  yield takeLatest(CHANNEL_POST_REQUEST, postChannel);
}

export function* onDeleteChannel() {
  yield takeLatest(CHANNEL_DELETE_REQUEST, deleteChannel);
}

export function* channelsWatcher() {
  yield all([
    call(onGetChannels),
    call(onPostChannel),
    call(onGetChannel),
    call(onDeleteChannel),
  ]);
}
