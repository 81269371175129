/* eslint-disable indent */
import produce from 'immer';
import {
  CHANNELS_LIST_FAILURE,
  CHANNELS_LIST_REQUEST,
  CHANNELS_LIST_SUCCESS,
  CHANNEL_POST_FAILURE,
  CHANNEL_POST_SUCCESS,
  CHANNEL_POST_REQUEST,
  CHANNEL_PUT_FAILURE,
  CHANNEL_PUT_SUCCESS,
  CHANNEL_PUT_REQUEST,
  CHANNEL_LIST_REQUEST,
  CHANNEL_LIST_FAILURE,
  CHANNEL_LIST_SUCCESS,
  CHANNEL_DELETE_REQUEST,
  CHANNEL_DELETE_FAILURE,
  CHANNEL_DELETE_SUCCESS,
} from './constants';

export const initialState = {
  data: null,
  error: '',
  loading: false,
  reset: false,
};

/* eslint-disable default-case, no-param-reassign */
const channelsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case CHANNELS_LIST_SUCCESS:
        draft.data = action.data;
        draft.loading = false;
        break;
      case CHANNELS_LIST_REQUEST:
        draft.loading = true;
        break;
      case CHANNELS_LIST_FAILURE:
        draft.error = action.error;
        draft.loading = false;
        break;
    }
  });

export const channelPostReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case CHANNEL_POST_REQUEST:
        draft.loading = true;
        draft.reset = false;
        break;
      case CHANNEL_POST_SUCCESS:
        draft.loading = false;
        draft.data = action.data;
        draft.reset = true;
        break;
      case CHANNEL_POST_FAILURE:
        draft.error = action.error;
        draft.loading = false;
        break;
    }
  });

export const channelPutReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case CHANNEL_PUT_REQUEST:
        draft.loading = true;
        break;
      case CHANNEL_PUT_SUCCESS:
        draft.loading = false;
        draft.data = action.data;
        draft.reset = true;
        break;
      case CHANNEL_PUT_FAILURE:
        draft.error = action.error;
        draft.loading = false;
        break;
    }
  });

export const channelReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case CHANNEL_LIST_REQUEST:
        draft.loading = true;
        break;
      case CHANNEL_LIST_SUCCESS:
        draft.loading = false;
        draft.data = action.data;
        draft.reset = true;
        break;
      case CHANNEL_LIST_FAILURE:
        draft.error = action.error;
        draft.loading = false;
        break;
    }
  });

export const channelDeleteReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case CHANNEL_DELETE_REQUEST:
        draft.loading = true;
        break;
      case CHANNEL_DELETE_SUCCESS:
        draft.loading = false;
        draft.data = action.data;
        draft.reset = true;
        break;
      case CHANNEL_DELETE_FAILURE:
        draft.error = action.error;
        draft.loading = false;
        break;
    }
  });

export default channelsReducer;
