import produce from 'immer';
import {
  SIGN_IN_SUCCESS,
  SIGN_IN_REQUEST,
  SIGN_IN_FAILURE,
  SIGN_OUT_REQUEST,
  SIGN_OUT_SUCCESS,
} from './constants';

export const initialState = {
  data: null,
  me: null,
  error: '',
  loading: false,
  isAuth: false,
  loadingSignOut: false,
};

/* eslint-disable default-case, no-param-reassign */
const authReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SIGN_IN_SUCCESS:
        draft.data = action.data;
        draft.loading = false;
        draft.isAuth = true;
        draft.me = action.me;
        draft.dataDevice = action.device;
        break;
      case SIGN_IN_REQUEST:
        draft.loading = true;
        break;
      case SIGN_IN_FAILURE:
        draft.error = action.error;
        draft.loading = false;
        break;
      case SIGN_OUT_REQUEST:
        draft.loadingSignOut = true;
        break;
      case SIGN_OUT_SUCCESS:
        draft.loadingSignOut = false;
        draft.me = null;
        draft.data = null;
        draft.isAuth = false;
        draft.dataDevice = null;
        localStorage.clear();
        break;
    }
  });

export default authReducer;
