export const GET_FORM_FIELD_TYPE_REQUEST =
  'SAPP/FORM_BUILDER/GET_FORM_FIELD_TYPE_REQUEST';
export const GET_FORM_FIELD_TYPE_SUCCESS =
  'SAPP/FORM_BUILDER/GET_FORM_FIELD_TYPE_SUCCESS';
export const GET_FORM_FIELD_TYPE_FAILURE =
  'SAPP/FORM_BUILDER/GET_FORM_FIELD_TYPE_FAILURE';

export const POST_FORM_FIELD_RESOURCE_REQUEST =
  'SAPP/FORM_BUILDER/POST_FORM_FIELD_RESOURCE_REQUEST';
export const POST_FORM_FIELD_RESOURCE_SUCCESS =
  'SAPP/FORM_BUILDER/POST_FORM_FIELD_RESOURCE_SUCCESS';
export const POST_FORM_FIELD_RESOURCE_FAILURE =
  'SAPP/FORM_BUILDER/POST_FORM_FIELD_RESOURCE_FAILURE';

export const GET_FORM_FIELD_BY_ID_REQUEST =
  'SAPP/FORM_BUILDER/GET_FORM_FIELD_BY_ID_REQUEST';
export const GET_FORM_FIELD_BY_ID_SUCCESS =
  'SAPP/FORM_BUILDER/GET_FORM_FIELD_BY_ID_SUCCESS';
export const GET_FORM_FIELD_BY_ID_FAILURE =
  'SAPP/FORM_BUILDER/GET_FORM_FIELD_BY_ID_FAILURE';
